import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const EmergingWritters = ({ data }) => {
  const { writters } = data;
  return (
    <Layout bgcolor={writters.backgroundColor} wrapperClass="emerging-writers" accentColor={writters.accentColor}>
      <HelmetDatoCms seo={writters.seoMetaTags} />
      <div className="main-block">
        <div className="main-title col">
          <h1 className="gothic--large large-title">{writters.title}</h1>
        </div>
        <div className="main-intro row">
          <div
            className="intro-text sans--regular col"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: writters.introduction,
            }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={writters.accentColor} />
          </div>
        </div>
        <div className="main-image col">
					<Img fluid={writters.featuredImage.fluid} alt={writters.featuredImage.alt} className="featured-title-image" />
          <p
            className="caption"
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: writters.featuredImage.title,
            }}
          />
        </div>
        <div className="main-text col">
          <div
            className="content emerging-writers-description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: writters.description,
            }}
          />
        </div>
      </div>
      <div className="emerging-writers-program container row">
        <div
          className="content text col-sm-7 offset-sm-2 col-xs-6 col"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: writters.firstTextBlock,
          }}
        />
      </div>
      {writters.newMentor.length > 0 && (
        <div className="emerging-writers-mentors container row">
          <div className="sidebar col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Mentors</span>
          </div>
          <div className="tiles tiles-3 col-sm-12 col-xs-6 row col spacing-0">
            {writters.newMentor.map((mentor) => (
              <div key={mentor.id} className="tile mentor-tile col">
								<ImageFadeIn
									src={mentor.image.featuredImage.url}
									alt={mentor.image.featuredImage.alt}
									className="tile-image mentor-featured-image"
								/>
                <div className="">
                  <div
                    className="content mentor-tile-text-wrapper tile-text-wrap"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: mentor.text,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="divider container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={writters.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

EmergingWritters.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default EmergingWritters;

export const query = graphql`
  query EmergingWrittersQuery {
    writters: datoCmsEmergingWriter {
      accentColor {
        hex
      }
      backgroundColor
      description
      featuredImage {
        alt
				fluid(maxHeight: 500) {
          ...GatsbyDatoCmsSizes_noBase64
        }      
			}
      firstTextBlock
      introduction
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      sideHeading
      title
      newMentor {
        id
        image {
          alt
					url(imgixParams: { w: "600", ar: "1:1", fit: "crop", crop: "focalpoint" })
        }
        text
      }
    }
  }
`;
